/* ADDICTION CONFIRMATION MESSAGE */
addiction-confirmation-message {

  .modal-input-container {
    min-width: 400px;
    max-width: 400px;
    height: auto;
    max-height: 800px;
    padding: 0 30px 30px 30px;
    border: none;
    border-radius: 0;
    background: $white;

    .header-container {
      //height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .title {
        width: 100%;
        @include font-style(30px, $font-light, $ko-color, 0, 100%);
      }

      .close-container {
        display: none;
      }

    }

    .body-container {
      //height: calc(100% - 56px);
      @include flex-start;
      flex-flow: column;
      flex: 1;
      overflow-y: auto;
      @include scroll-bar;

      .text {
        width: 100%;
        @include font-style(14px, $font-medium, $black, 0, 150%);
        text-align: center;

        span {
          font-size: 18px;
          font-weight: $font-medium;
        }

        ul {
          li {
            text-align: left;
          }
        }


      }

      .button-container {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 0;
        @include flex-center;
        flex-flow: column;
        row-gap: 10px;

        button {
          width: 100% !important;
          margin: 0 10px;

          &:first-of-type {
            @include button-style(47px, $ko-color, $white, "", 0, $buttonIcon: false, $buttonBackground: true);
          }

          &:last-of-type {
            @include button-style(47px, $primary, $white, "", 0, $buttonIcon: false, $buttonBackground: false);
          }

        }

      }

    }

  }

}
