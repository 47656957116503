/* ADDICTION PHONE */
addiction-phone {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .form-group-container {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
    @include form-fields-container;

    .title-fields-container {
      margin-top: 0;

      h2 {
        @include tooltip($white, 15px);
      }

    }

  }

}
