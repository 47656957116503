/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@include mat.core();

$theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      tertiary: mat.$blue-palette,
    ),
  )
);
@include mat.color-variants-backwards-compatibility($theme);

$grey: #e7e9ed;
$grey-lighter: #f6f7f9;
$grey-dark: #f3f3f3;
$dark: #50596c;
$blue: #3f86ca;
$primary-color: #50596c !default;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 1px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: white;
  padding-top: 9px;
  padding-left: 4px;
  display: table;
  table-layout: fixed;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
}

.ngx-draggable-row-area.cdk-drop-list-dragging .ngx-draggable-row:not(.cdk-drag-placeholder) {
  transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
}

.ngx-infinite-scroll-viewport {
  display: block;
  height: 250px;
  overflow: auto;
}

.ngx-infinite-scroll-viewport tr,
.ngx-infinite-scroll-viewport-thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* STILE GLOBAL */
/* You can add global styles to this file, and also import other style files */
@import 'mixins.scss';
@import 'standard-mixins.scss';
@import 'form-fields.scss';
@import 'fonts.scss';
@import 'variables.scss';
@import 'addiction-components/addiction-autocomplete.scss';
@import 'addiction-components/addiction-checkbox.scss';
@import 'addiction-components/addiction-confirmation-message.scss';
@import 'addiction-components/addiction-date-picker.scss';
@import 'addiction-components/addiction-email.scss';
@import 'addiction-components/addiction-html-editor.scss';
@import 'addiction-components/addiction-input.scss';
@import 'addiction-components/addiction-phone.scss';
@import 'addiction-components/addiction-radio.scss';
@import 'addiction-components/addiction-search.scss';
@import 'addiction-components/addiction-select.scss';
@import 'addiction-components/addiction-textarea.scss';
@import 'addiction-components/datalean-modal-header.scss';
@import 'pagination.scss';

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  background: $brand-color-03;
  overflow: hidden;

  * {
    box-sizing: border-box;
    transition: $standard-transition;
    outline: none;
  }

  .cdk-drag-preview {

    &.single-element {
      width: 100%;
      @include font-style(14px, $font-regular, $black, 0, 130%);
      cursor: move;
      padding: 20px;
      border-radius: 5px;
    }

    &.field-container {
      padding: 0;
      border-radius: 5px;
      /* Stile uguale a form-fields.scss per stilare mentre si fa drag */

      .single-content-container {
        width: 100%;
        overflow: hidden;

        &.collapsed {
          max-height: 50px; //single-header height + its margin

          .single-header {
            background: $primary;
          }

        }

        .single-header {
          width: calc(100% - 10px);
          height: 40px;
          background: $brand-color-04;
          border-radius: 2px;
          margin: 5px;
          padding: 5px 10px;
          display: flex;
          align-items: center;
          cursor: move;

          &.invalid {
            background: $ko-color;
          }

          .left-container {
            flex: 1;
            display: flex;
            align-items: center;

            .button.drag {
              width: 10px;
              height: 20px;
              background: $transparent;
              border: none;
              @include flex-center;
              position: relative;
              margin-right: 10px;
              pointer-events: none;

              &::before,
              &::after {
                content: 'a';
                width: 10px;
                height: 10px;
                font-size: 6px;
                color: $white;
                font-family: $font-icon;
                @include flex-center;
                position: absolute;
              }

              &::before {
                transform: rotate(-180deg);
                top: 0;
              }

              &::after {
                bottom: 0;
              }

            }

            .header-title {
              @include font-style(12px, $font-medium, $white, 1.2px, 120%, false);
              text-transform: uppercase;
              margin: 0;
            }

            .deprecated-text {
              @include font-style(8px, $font-medium, $white, 1.2px, 120%, false);
              text-transform: uppercase;
              margin-left: 10px;
            }
          }

          .right-container {
            flex: 1;
            padding: 0;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: flex-end;
            column-gap: 20px;

            button,
            .button {
              width: 20px;
              height: 20px;
              background: $transparent;
              border: none;
              @include flex-center;
              position: relative;

              &::before {
                width: 20px;
                height: 20px;
                font-size: 18px;
                color: $white;
                font-family: $font-icon;
                @include flex-center;
                position: absolute;
                cursor: pointer;
              }

              &.delete {
                &::before {
                  content: 'f';
                }
              }

              &.edit {
                &::before {
                  content: 'j';
                }
              }

              &.duplicate {
                &::before {
                  content: 'i';
                }
              }

              &.restore {
                &::before {
                  content: 's';
                }
              }

              &.undo {
                &::before {
                  content: 'u';
                }
              }

              &.save {
                &::before {
                  content: 't';
                }
              }

              //TODO: aggiornare icona per aggiornamento sezioni obsolete
              &.update {
                &::before {
                  content: 't';
                }
              }

              @media (hover: hover) and (pointer: fine) {
                &:hover {
                  opacity: 0.5;
                }
              }
            }
          }

          &.deprecated {
            background: $deprecated-color;
          }

        }

        .single-body {
          width: 100%;
          padding: 15px 10px;

          .text {
            width: 100%;
            @include font-style(14px, $font-regular, $black, 0, 180%);

            p {
              @include font-style(14px, $font-regular, $black, 0, 180%);
              margin: 0 0 10px 0;
            }

          }

        }

      }

    }

  }

}

.cdk-overlay-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize($black, 0.85);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.cdk-overlay-container {

  * {
    transition: none;
  }

  .cdk-overlay-pane {

    &.fullscreen {
      // FULL SCREEN VARIANT
      width: 100%;
      height: 100%;
      max-height: 100%;
    }

  }

  .cdk-global-overlay-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex-center;

    .cdk-overlay-pane {
      max-height: calc(100% - 80px);
      max-width: calc(100% - 80px);

      .mat-mdc-dialog-container {
        height: auto;
        min-height: unset;
        max-height: 800px;
        max-width: 100%;

        .mdc-dialog__container {
          .mat-mdc-dialog-surface {
            modal-dialog {
              .modal-dialog {
                modal-header {
                }

                .modal-content {
                  datalean-tree-selector-dialog {
                    datalean-tree-browser {
                      height: calc(100% - 60px);

                      .top-bar {
                        height: 60px;

                        addiction-search {
                          addiction-input {
                            .form-field {
                              .form-input-container {
                                width: 100%;
                              }
                            }
                          }
                        }

                        .buttons {
                          display: none;
                        }
                      }

                      .tree-browser {
                        height: calc(100% - 60px);
                      }
                    }

                    > div {
                      width: 100%;
                      height: 60px;
                      padding-top: 20px;
                      display: flex;
                      justify-content: center;

                      button {
                        //Button Aggiungi
                        height: 40px;
                        min-width: 150px;
                        border: none;
                        background: $brand-color-04;
                        border-radius: 10px;
                        @include font-style(12px, $font-regular, $black, 0, 130%);
                        text-transform: uppercase;
                        cursor: pointer;
                        transition: $standard-transition;

                        @media (hover: hover) and (pointer: fine) {
                          &:hover {
                            background: $black;
                            color: $brand-color-04;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /* ES. POPUP PER NUOVA IMMAGINE + POPUP PER NUOVA CARTELLA NELLA MEDIA LIBRARY*/
  mat-dialog-container {
    background-color: $white;
    width: 400px;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 10px 50px transparentize($black, 0.85);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;

    .mdc-dialog__container {
      width: 100%;
      height: 100%;

      .mat-mdc-dialog-surface {
        background: $transparent;
        box-shadow: none;
        overflow: hidden;

        modal-dialog {
          height: auto;
          //da qui in poi, stile nel file modal-dialog.component.scss
        }
      }
    }
  }

  .cdk-overlay-connected-position-bounding-box {

    .cdk-overlay-pane {
      transform: translateX(0) !important;

      /* POPUP PER SELECT */
      .mat-mdc-select-panel {
        min-width: calc(100% - 15px) !important;
        background: $brand-color-03;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: none;
        @include scroll-bar-dark;

        .mat-mdc-option {
          padding: 0 10px;
          height: 40px;
          background: $transparent !important;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              .mdc-list-item__primary-text {
                color: $black;
              }
            }
          }

          &.mdc-list-item--selected {
            .mdc-list-item__primary-text {
              color: $black;
            }
          }

          .mdc-list-item__primary-text {
            @include font-style(12px, $font-regular, $black, 0, 130%);
            cursor: pointer;
            transition: $standard-transition;
          }

          mat-pseudo-checkbox {
            width: 18px;
            height: 18px;
            border: none;
            background: $brand-color-06;
            border-radius: 5px;
            @include flex-center;

            &.mat-pseudo-checkbox-checked {
              background: $brand-color-04;

              &::before {
                opacity: 1;
              }
            }

            &::before {
              content: '';
              width: 7px;
              height: 4px;
              position: absolute;
              border-bottom: 2px solid $white;
              border-left: 2px solid $white;
              margin-bottom: 2px;
              transform: rotate(-45deg);
              opacity: 0;
              z-index: 1;
              cursor: pointer;
              transition: $standard-transition;
            }

            &::after {
              display: none;
            }
          }

          .mat-ripple {
            display: none;
          }
        }
      }

      /* POPUP PER MENU */
      /* es. user-menu */
      .mat-mdc-menu-panel {
        width: 100%;
        min-width: 200px;
        max-height: 600px;
        background: $brand-color-03;
        box-shadow: 0 5px 10px transparentize($black, 0.9);
        border-radius: 5px;
        @include scroll-bar;

        .mat-mdc-menu-content {
          padding: 5px 0;

          .mat-mdc-menu-item {
            padding: 10px 20px;
            min-height: 40px;
            transition: $standard-transition;
            cursor: pointer;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                background: $transparent !important;

                &.with-icon {
                  &::before {
                    opacity: 1;
                  }
                }

                &.DELETE,
                &.INVALID,
                &.RESET {
                  .mat-mdc-menu-item-text {
                    label.date-picker,
                    span {
                      color: $ko-color;
                    }
                  }
                }

                .mat-mdc-menu-item-text {
                  label.date-picker,
                  span {
                    color: $black;
                  }
                }
              }
            }

            &.with-icon {
              padding: 0 20px 0 50px;

              &::before {
                width: 16px;
                height: 16px;
                font-size: 16px;
                color: $black;
                font-family: $font-icon;
                position: absolute;
                cursor: pointer;
                @include flex-center;
                left: 20px;
                top: unset;
                bottom: unset;
                right: unset;
                margin: 0;
                border: none;
                border-radius: 0;
                opacity: 0.4;
                transition: $standard-transition;
              }

              &.it-IT {
                &::before {
                  content: '';
                  background: url('/assets/images/languages/it-IT.jpg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }

              &.en-US {
                &::before {
                  content: '';
                  background: url('/assets/images/languages/en-US.jpg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }

              &.fr-FR {
                &::before {
                  content: '';
                  background: url('/assets/images/languages/fr-FR.jpg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }

              &.de-DE {
                &::before {
                  content: '';
                  background: url('/assets/images/languages/de-DE.jpg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }

              &.es-ES {
                &::before {
                  content: '';
                  background: url('/assets/images/languages/es-ES.jpg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }
            }

            &.DELETE,
            &.INVALID,
            &.RESET {
              .mat-mdc-menu-item-text {
                label.date-picker,
                span {
                  color: transparentize($ko-color, 0.5);
                }
              }
            }

            .mat-mdc-menu-item-text {
              width: 100%;
              position: relative;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-flow: row wrap;
              @include font-style(12px, $font-regular, $black, 0, 130%);
              cursor: pointer;

              label.date-picker {
                /* quando c'è date-picker */
                @include font-style(12px, $font-regular, $black, 0, 130%);
                text-transform: uppercase;
                margin-bottom: 5px;
                cursor: pointer;
                transition: $standard-transition;
              }

              mat-checkbox {
                width: auto;
                @include mat-checkbox-style($white, $primary, none);

                .mdc-form-field {
                  .mdc-label {
                    @include font-style(12px, $font-regular, $black, 0, 130%);
                  }
                }
              }

              span {
                @include font-style(12px, $font-regular, $black, 0, 130%);
                margin-right: auto;
                cursor: pointer;
                transition: $standard-transition;
              }

              addiction-radio {
                height: auto;

                .form-field.radio {
                  mat-radio-group {
                    flex-flow: column;
                    row-gap: 20px;

                    mat-radio-button {
                      .mdc-form-field {
                        .mdc-label {
                          @include font-style(12px, $font-regular, $black, 0, 130%);
                        }
                      }
                    }
                  }
                }
              }
            }

            .mat-ripple {
              display: none;
            }

            .mat-mdc-menu-submenu-icon {
              display: none;
            }

            &.mat-mdc-menu-item-submenu-trigger {

              .mat-mdc-menu-item-text {
                padding-right: 10px;

                &::before {
                  content: 'a';
                  width: 10px;
                  height: 10px;
                  font-size: 7px;
                  font-family: $font-icon;
                  position: absolute;
                  right: -10px;
                  cursor: pointer;
                  transition: $standard-transition;
                  @include flex-center;
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }

      /* POPUP COMPONENTE <addiction-date-picker> */
      &.mat-datepicker-popup {
        * {
          color: $black;
          fill: $black;
        }

        .mat-datepicker-content {
          background: $brand-color-03;

          .mat-datepicker-content-container {
            mat-calendar {
              mat-calendar-header {
                .mat-calendar-header {
                  .mat-calendar-controls {
                    margin: 0;

                    > button {
                    }
                  }
                }
              }

              .mat-calendar-content {
                mat-month-view {
                  .mat-calendar-table {
                    .mat-calendar-table-header {
                      display: none;
                    }

                    .mat-calendar-body {
                      .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
                        border-color: transparentize($white, 0.6);
                      }
                    }
                  }
                }
              }
            }

            .mat-datepicker-close-button {
            }
          }
        }
      }
    }

    /* STILE TOOLTIP */
    .mat-mdc-tooltip-panel {
      mat-tooltip-component {
        .mdc-tooltip {
          .mdc-tooltip__surface {
            @include font-style(12px, $font-regular, $white, 0, 130%);
            background: $primary;
            border-radius: 5px;
            position: relative;
            padding: 5px 10px;
            margin-left: 10px;
            overflow: visible;
            margin-bottom: 10px;

            // &::before {
            //   content: '';
            //   width: 10px;
            //   height: 10px;
            //   transform: rotate(-90deg);
            //   border-width: 6px;
            //   border-style: solid;
            //   border-color: transparent transparent $primary transparent;
            //   position: absolute;
            //   top: unset;
            //   left: -11px;
            //   margin-top: 1px;
            // }
          }
        }
      }
    }
  }
}

/* MENU AL CLICK DEL PROFILO IN ALTO A DESTRA */
.profile-menu {
  position: absolute;
  right: 0;
  background: $white;
  box-shadow: 0 5px 10px transparentize($black, 0.9);
  display: flex;
  justify-content: center;
  flex-flow: column;
  gap: 20px;
  padding: 15px;
  border-radius: 10px;
  margin: 20px 10px 0 0;

  button {
    height: auto;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: column;
    background: $transparent;
    border: none;
    border-radius: 0;
    @include font-style(12px, $font-medium, $black, 0.5px, 100%);
    cursor: pointer;
    transition: $standard-transition;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        opacity: 0.5;
      }
    }

    &.profile {}

    &.logout {}

  }

}

mat-tab-group {
  height: 100%;
  width: 100%;

  .mat-mdc-tab-body-wrapper {
    height: 100%;
  }

}
