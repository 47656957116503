@import 'variables.scss';

/* --- SPECIFIC MIXINS --- */

@mixin input-checkbox($invert: false) {
  width: 18px;
  height: 18px;
  margin: 0;
  @include flex-center;
  appearance: none;
  cursor: pointer;
  position: relative;
  background: $transparent;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &::before {
      cursor: not-allowed;
    }

    &::after {
      cursor: not-allowed;
    }
  }

  &::before {
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    background: $primary;
    border-radius: 5px;
    z-index: 0;
    cursor: pointer;
  }

  &::after {
    content: '';
    width: 7px;
    height: 4px;
    position: absolute;
    border-bottom: 2px solid $white;
    border-left: 2px solid $white;
    margin-bottom: 2px;
    transform: rotate(-45deg);
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    transition: $standard-transition;
  }

  &:checked {
    &::before {
      background: $primary;
    }

    &::after {
      opacity: 1;
      background: $transparent;
    }
  }

  @if $invert {
    &:checked {
      &::before {
        background: $white;
      }
    }

    &::after {
      content: '';
      border-bottom-color: $primary;
      border-left-color: $primary;
    }

    &::before {
      content: '';
      background: $white;
    }
  }
}

//Campo slider/range tipo per dimensione elementi media library
@mixin mat-slider {
  min-width: 200px;
  --mat-mdc-slider-focus-ripple-color: none;

  .mdc-slider__track {
    height: 1px;
    background: $primary;

    .mdc-slider__track--inactive {
      height: 1px;
      background: $primary;
    }

    .mdc-slider__track--active {
      display: none;
    }
  }

  mat-slider-visual-thumb {
    .mdc-slider__thumb-knob {
      border-radius: 3px;
      border: 1px solid $primary;
      background: $brand-color-02;
      box-shadow: none;
    }
  }

  /* PRIMA, DA CAPIRE SE SERVE ANCORA */
  .mat-mdc-slider-wrapper {
    height: 1px;
    background: $primary;

    .mat-mdc-slider-track-wrapper {
      height: 1px;
      background: $primary;
    }

    .mat-mdc-slider-ticks-container {
      height: 1px;
      background: $primary;
    }

    .mat-mdc-slider-thumb-container {
      .mat-mdc-slider-focus-ring,
      .mat-mdc-slider-thumb-label {
        display: none;
      }

      .mat-mdc-slider-thumb {
        transform: none;
        width: 19px !important;
        height: 19px !important;
        border: 1px solid $primary;
        background: $brand-color-01;
        border-radius: 3px;
      }
    }
  }

  ::ng-deep {
    .mdc-slider__track {
      height: 1px;
      background: $primary;

      .mdc-slider__track--inactive {
        height: 1px;
        background: $primary;
      }

      .mdc-slider__track--active {
        display: none;
      }
    }

    mat-slider-visual-thumb {
      .mdc-slider__thumb-knob {
        border-radius: 3px;
        border: 1px solid $primary;
        background: $brand-color-02;
        box-shadow: none;
      }
    }

    /* PRIMA, DA CAPIRE SE SERVE ANCORA */
    .mat-mdc-slider-wrapper {
      height: 1px;
      background: $primary;

      .mat-mdc-slider-track-wrapper {
        height: 1px;
        background: $primary;
      }

      .mat-mdc-slider-ticks-container {
        height: 1px;
        background: $primary;
      }

      .mat-mdc-slider-thumb-container {
        .mat-mdc-slider-focus-ring,
        .mat-mdc-slider-thumb-label {
          display: none;
        }

        .mat-mdc-slider-thumb {
          transform: none;
          width: 19px !important;
          height: 19px !important;
          border: 1px solid $primary;
          background: $brand-color-01;
          border-radius: 3px;
        }
      }
    }
  }
}

//@include mat-checkbox-style(transparentize($white, 0.9), $primary, none);
@mixin mat-checkbox-style($background, $backgroundActive, $borderColor) {
  .mdc-form-field {
    .mdc-checkbox {
      padding: 0;

      .mat-mdc-checkbox-touch-target {
        display: none;
      }

      input {
        width: 18px;
        height: 18px;

        &:enabled:checked ~ .mdc-checkbox__background {
          border-color: unset;
          background-color: $backgroundActive !important;
        }

        &:enabled:indeterminate ~ .mdc-checkbox__background,
        &.mdc-checkbox--selected ~ .mdc-checkbox__background {
          border-color: unset;
          background-color: $backgroundActive !important;

          .mdc-checkbox__mixedmark {
            transform: scale(0.5);
            border-color: $white !important;
          }
        }
      }

      .mdc-checkbox__ripple {
        display: none;
      }

      .mdc-checkbox__background {
        width: 18px;
        height: 18px;
        top: unset;
        left: unset;
        border-radius: 3px;
        background-color: $background !important;
        border: $borderColor !important;

        svg {
          transform: scale(0.6);
        }

        .mdc-checkbox__mixedmark {
          transform: scale(0);
        }
      }

      .mat-mdc-checkbox-ripple {
        display: none;
      }
    }

    .mdc-label {
      padding-left: 10px;
      @include font-style(12px, $font-regular, $white, 0, 150%);
    }
  }
}

/* Stile per buttons che al click aprono un sottomenù */
@mixin button-mat-menu($content, $background, $color, $size, $backgroundActive, $colorActive) {
  @include flex-center;
  cursor: pointer;
  background: none;
  padding: 0;
  outline: 0;
  border: none;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 10px;
    background: $background;
    position: absolute;
    bottom: -10px;
    opacity: 0;
  }

  &::after {
    content: $content;
    width: 100%;
    height: 100%;
    font-family: $font-icon;
    font-size: $size;
    position: absolute;
    color: $color;
    cursor: pointer;
    @include flex-center;
  }

  &.mat-menu-below {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    &::before {
      bottom: -10px;
    }
  }

  &.mat-menu-above {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    &::before {
      top: -10px;
    }
  }

  &[aria-expanded='false'] {
    box-shadow: none;
  }

  &[aria-expanded='true'] {
    background: $backgroundActive;

    &::before {
      opacity: 1;
    }

    &::after {
      color: $colorActive;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 0.5;
    }
  }

  mat-icon {
    display: none;
  }
}

@mixin right-options-detail {
  height: 100%;
  width: 300px;
  min-width: 300px;
  background: $white;
  border-left: 1px solid $brand-color-03;
  overflow-x: hidden;
  overflow-y: auto;
  @include scroll-bar;
  display: flex;
  flex-flow: column;
  row-gap: 20px;

  &::ng-deep {
    .statistics-container {
      width: 100%;
      height: auto;
      padding: 20px;
      background: linear-gradient(to right, $brand-color-02, $primary);
      border-radius: 10px;
      display: flex;
      flex-flow: column;
      row-gap: 20px;
    }

    .percentage-indicator-container {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 20px;

      datalean-percentage-indicator::ng-deep {
        width: 60%;

        .percentage-container {
          .details {
            .number,
            .percentage {
              @include font-style(15px, $font-regular, $white, 0, 150%);
            }
          }
        }
      }

      label {
        width: 40%;
        @include font-style(13px, $font-regular, $primary, 0, 130%);
        text-align: right;
      }
    }

    .fields-container {
      @include form-fields-container;
      margin-bottom: 20px;
      padding: 20px;

      .field-container {
        padding: 0;
      }
    }

    .buttons-options-container {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      row-gap: 20px;
      column-gap: 20px;
      padding: 20px;

      button {
        &.save {
          @include button-style(47px, $primary, $white, 'l', 14px, $buttonIcon: true, $buttonBackground: true);
        }

        &.delete {
          @include button-style(47px, $ko-color, $white, 'f', 14px, $buttonIcon: true, $buttonBackground: true);
        }

        &.add {
          @include button-style(47px, $primary, $white, 'c', 14px, $buttonIcon: true, $buttonBackground: false);
        }

        &.exit {
          @include button-style(47px, $primary, $white, 'q', 14px, $buttonIcon: true, $buttonBackground: false);
        }

        &.save-as-template {
          @include button-style(47px, $primary, $white, 'm', 14px, $buttonIcon: true, $buttonBackground: false);
        }

        &.save-template {
          @include button-style(47px, $primary, $white, 'm', 14px, $buttonIcon: true, $buttonBackground: true);
        }

        &.create-template {
          @include button-style(47px, $primary, $white, 'c', 14px, $buttonIcon: true, $buttonBackground: false);
        }

        &.clone {
          @include button-style(47px, $primary, $white, 'i', 14px, $buttonIcon: true, $buttonBackground: false);
        }

        &.print {
          @include button-style(47px, $primary, $white, 'r', 14px, $buttonIcon: true, $buttonBackground: false);
        }

        &.document {
          @include button-style(47px, $primary, $white, 'h', 14px, $buttonIcon: true, $buttonBackground: false);
        }

        &.ratio-100 {
          width: 100% !important;
        }

        &.ratio-50 {
          width: calc(50% - 10px) !important;
        }
      }

      .status-container {
        width: calc(100% + 40px);
        padding: 20px;
        margin-right: -20px;
        margin-left: -20px;
        display: flex;
        flex-flow: column;
        border-top: 1px solid $brand-color-03;
        border-bottom: 1px solid $brand-color-03;

        label {
          @include font-style(12px, $font-medium, $black, 0.6px, 100%);
          margin-bottom: 5px;
        }

        addiction-select {
          &::ng-deep {
            mat-select {
              .mat-mdc-select-min-line {
                font-size: 14px;
              }
            }
          }
        }
      }

      &.bottom {
        margin-top: auto;
        border-top: 1px solid $brand-color-03;
      }
    }

    .anchors-list-container {
      width: 100%;
      display: flex;
      flex-flow: column;
      row-gap: 10px;
      margin-top: 20px;
      padding: 20px;

      .single-anchor {
        width: 100%;
        min-height: 40px;
        display: flex;
        align-items: center;
        position: relative;

        &::before {
          content: '';
          width: 0;
          height: 100%;
          background: $primary;
          position: absolute;
          left: -30px;
          transition: $standard-transition;
        }

        &.active {
          &::before {
            width: 3px;
          }

          a {
            color: $white;
          }
        }

        a {
          width: 100%;
          max-width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          @include font-style(19px, $font-regular, transparentize($white, 0.6), 0.8px, 130%);
          cursor: pointer;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          transition: $standard-transition;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }
}

@mixin mat-tab-header {
  width: 100%;
  height: 60px;
  @include flex-center;
  @include font-style(14px, $font-medium, $black, 1.4px, 100%, true);
  text-transform: uppercase;
  position: relative;
  border-bottom: 1px solid $brand-color-03;

  .mat-mdc-tab-label-container {
    height: 100%;
    border-bottom-style: none;

    .mat-mdc-tab-labels {
      height: 100%;

      .mdc-tab {
        border-right: 1px solid $brand-color-03;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: $brand-color-04;
          }
        }

        &:last-of-type {
          border-right: none;
        }

        /*         .mdc-tab-indicator {
          .mdc-tab-indicator__content--underline {
            border-color: $brand-color-03;
            border-width: 1px;
            transition: all 0.5s $bezier;
												opacity: 1;
          }
        } */

        &.mdc-tab--active {
          .mdc-tab-indicator {
            .mdc-tab-indicator__content--underline {
              border-color: $brand-color-04;
              border-width: 5px;
            }
          }
        }
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: $brand-color-04;
    }
  }
}

@mixin view-mode-buttons {
  @include flex-center;

  .view-button {
    width: 20px;
    height: 47px;
    background: $transparent;
    padding: 0;
    border: none;
    border-radius: 0;
    position: relative;
    @include flex-center;
    cursor: pointer;
    transition: all 0.5s $bezier;
    margin-left: 20px;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        opacity: 0.5;
      }
    }

    &::before {
      content: 'v';
      width: 16px;
      height: 16px;
      color: $white;
      font-size: 16px;
      font-family: $font-icon;
      position: absolute;
      cursor: pointer;
      transition: all 0.5s $bezier;
      @include flex-center;
      pointer-events: none;
    }

    &.active {
      &::before {
        color: $primary;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          opacity: 1;
        }
      }
    }

    &.list-view {
      &::before {
        content: 'A';
      }
    }

    &.grid-view {
      &::before {
        content: 'z';
      }
    }
  }
}
