/* ADDICTION AUTOCOMPLETE */
addiction-autocomplete {
  width: 100%;
  display: flex;

  .form-field {
    width: 100%;
    display: flex;
    flex-flow: column;

    &.disabled,
    &.readonly {

      .form-input-container {
        opacity: 0.5;

        input {
          cursor: not-allowed;
        }

      }

    }

    label {
      @include fieldLabel;
    }

    .form-input-container {
      width: 100%;
      display: flex;

      input {
        width: 100%;
        height: 47px;
        flex: unset;
        padding: 5px 10px;
        margin: 0;
        caret-color: $black;
        border: none;
        box-sizing: border-box;
        background: $brand-color-03;
        border-radius: 5px;
        @include font-style(14px, $font-medium, $black, 0, 100%);
        cursor: inherit;
      }

    }

    &.disabled,
    &.readonly {

      .form-input-container {
        opacity: 0.5;

        input {
          cursor: not-allowed;
        }

      }

    }
  }

  mat-autocomplete {}

}

/* POPUP */
.cdk-overlay-container {

  .cdk-overlay-connected-position-bounding-box {

    .cdk-overlay-pane {

      /* POPUP AUTOCOMPLETE */
      .mat-mdc-autocomplete-panel {
        border: none;
        border-radius: 0;
        margin-top: -4px;
        box-shadow: none;
        padding: 0;
        background: $secondary;
        @include scroll-bar;

        mat-optgroup {
          background: $secondary;
          transition: $standard-transition;

          .mat-mdc-optgroup-label {
            background: $secondary;

            .mdc-list-item__primary-text {
              @include font-style(12px, $font-medium, transparentize($white, 0.25), 1.2px, 130%);
            }

          }

        }

        mat-option {
          padding: 10px 16px;
          background: $secondary;
          transition: $standard-transition;

          @media (hover: hover) and (pointer: fine) {

            &:hover {
              opacity: 0.5;
            }

          }

          .mdc-list-item__primary-text {
            @include font-style(12px, $font-medium, transparentize($white, 0.25), 1.2px, 130%);
            cursor: pointer;
          }

          mat-pseudo-checkbox {
            display: none;
          }

          .mat-ripple {
            display: none;
          }

        }

      }

    }

  }

}
