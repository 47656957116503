@import "variables.scss";

/* --- STANDARD MIXINS --- */

/* --- FLEX --- */
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@mixin flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

/* --- FONTS --- */
//@include font-style(17px, $font-regular, $primary, 0, 200%, false);
@mixin font-style($size, $weight, $color, $letter, $line, $link: false) {
  font-family: $font-default;
  font-size: $size;
  font-weight: $weight;
  color: $color;
  line-height: $line;
  letter-spacing: $letter;

  @if $link {
    cursor: pointer;
    transition: $standard-transition;
  } @else {
    cursor: default;
  }

}

@mixin scroll-bar {
  scrollbar-width: thin;
  scrollbar-color: $brand-color-03 $transparent;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: $brand-color-03;
    box-shadow: none;
  }

  &::-webkit-scrollbar-track {
    background-color: $transparent;
  }

}

/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@mixin scroll-bar-dark {
  scrollbar-width: thin;
  scrollbar-color: $brand-color-06 $transparent;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $brand-color-06;
    box-shadow: none;
  }

  &::-webkit-scrollbar-track {
    background-color: $transparent;
  }

}

@mixin skeleton-loading {
  background: linear-gradient(110deg, transparentize($brand-color-03, 0) 40%, transparentize($brand-color-03, 0.5) 60%, transparentize($brand-color-03, 0) 70%) $white;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {

  to {
    background-position-x: -20%;
  }

}

/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $primary, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $primary, $primary, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $primary, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $primary, $primary, $buttonIcon: false, $buttonBackground: false);
*/
@mixin button-style($height, $primaryColor, $secondaryColor, $content, $iconSize, $buttonIcon: true, $buttonBackground: true) {
  width: auto;
  min-height: $height;
  padding: 5px 25px;
  border-radius: 5px;
  @include flex-center;
  @include font-style(14px, $font-semibold, $primaryColor, 1.4px, 130%);
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s $bezier;

  @media (hover: hover) and (pointer: fine) {

    &:hover {
      opacity: 0.5;
    }

  }

  @if $buttonIcon {
    padding: 5px 25px 5px 40px;
    position: relative;

    &::before {
      content: $content;
      width: $iconSize;
      height: $iconSize;
      font-size: $iconSize;
      color: $primaryColor;
      font-weight: $font-regular;
      letter-spacing: 0;
      line-height: 100%;
      font-family: $font-icon;
      text-transform: none;
      position: absolute;
      left: 15px;
      cursor: pointer;
      transition: all 0.5s $bezier;
    }

  }

  span {
    width: 100%;
    @include font-style(14px, $font-semibold, $primaryColor, 1.4px, 130%);
    cursor: pointer;
    text-align: center;
    @include flex-center;
    text-transform: uppercase;
    margin-top: 3px;
    transition: $standard-transition;
  }

  @if $buttonBackground {
    border: 2px solid $primaryColor;
    background: $primaryColor;

    &::before {
      color: $secondaryColor;
    }

    span {
      color: $secondaryColor;
    }

  } @else {
    background: $transparent;
    border: 2px solid $primaryColor;

    &::before {
      color: $primaryColor;
    }

    span {
      color: $primaryColor;
    }

  }

  &:disabled,
  &.disabled,
  &[disabled],
  &[disabled='true'] {
    cursor: not-allowed;
    pointer-events: none;

    span {
      cursor: not-allowed;
      pointer-events: none;
    }

    @if $buttonBackground {
      border: 2px solid $brand-color-03;
      background: $brand-color-03;
    } @else {
      background: $transparent;
      border: 2px solid $brand-color-03;

      &::before {
        color: $brand-color-03;
      }

      span {
        color: $brand-color-03;
      }

    }

  }

}

@mixin no-results {
  width: 100%;
  height: auto;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;

  &.bigger {

    ng-lottie {
      width: 400px;
      height: 400px;
      margin-bottom: 0;
    }

  }

  ng-lottie {
    display: flex;
    width: 250px;
    height: 250px;
    margin-bottom: 30px;
    object-fit: contain;
  }

  .title {
    width: 100%;
    margin-bottom: 10px;
    @include font-style(20px, $font-medium, $white, 2.5px, 150%);
    text-transform: uppercase;
    text-align: center;
  }

  .text {
    width: 100%;
    @include font-style(14px, $font-regular, $white, 0, 150%);
    text-align: center;

    span {
      color: $brand-color-01;
    }

  }

}

@mixin tooltip($color, $size) {
  display: flex;
  align-items: center;

  .tooltip {
    width: 15px;
    height: 15px;
    @include flex-center;
    margin-left: 10px;
    position: relative;
    cursor: pointer;

    &::before {
      content: "F";
      font-family: $font-icon;
      font-size: $size;
      color: $color;
      position: absolute;
      pointer-events: none;
    }

  }

}

@mixin fieldLabel {
  width: 100%;
  display: flex;
  @include font-style(12px, $font-medium, $black, 1.2px, 120%);
  text-transform: uppercase;
  margin-bottom: 5px;
  @include tooltip($white, 13px);

  .required {
    @include font-style(12px, $font-medium, $black, 1.2px, 120%);
    text-transform: uppercase;
  }

}

@mixin asset-selector {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 5px;
  border: 2px solid $brand-color-03;
  position: relative;
  overflow: hidden;

  .asset {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .single-button {
    width: 27px;
    height: 27px;
    border-radius: 5px;
    @include flex-center;
    position: absolute;
    bottom: 5px;

    &::before {
      width: 100%;
      height: 100%;
      color: $white;
      font-size: 15px;
      font-family: $font-icon;
      @include flex-center;
      cursor: pointer;
    }

    &.open {
      background: $brand-color-01;
      left: 5px;

      &::before {
        content: "e";
      }

    }

    &.delete {
      background: $ko-color;
      right: 5px;

      &::before {
        content: "f";
      }

    }

  }

}
