/* ADDICTION TEXTAREA */
addiction-textarea {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .form-field {
    width: 100%;

    &.disabled,
    &.readonly {

      .form-input-container {}

    }

    label {
      @include fieldLabel;
    }

    .form-input-container {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;

      textarea {
        width: 100%;
        height: 180px;
        @include font-style(14px, $font-regular, $white, 0, 160%);
        cursor: text;
        padding: 10px;
        background: $brand-color-03;
        border: 0;
        margin: 0;
        border-radius: 5px;
        appearance: none;
        transform: none;

        &:disabled,
        &:read-only {
          cursor: not-allowed;
          opacity: 0.5;
        }

        &:focus-visible {
          outline: none;
        }

      }

    }

  }

}
