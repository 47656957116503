/* FONT BARLOW CONDENSED */
@font-face {
  font-family: 'Barlow Condensed';
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-Thin.eot');
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-Thin.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Thin.woff2') format('woff2'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Thin.woff') format('woff'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Thin.ttf') format('truetype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Thin.svg#BarlowCondensed-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-ExtraLight.eot');
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-ExtraLight.woff2') format('woff2'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-ExtraLight.woff') format('woff'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-ExtraLight.ttf') format('truetype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-ExtraLight.svg#BarlowCondensed-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-Light.eot');
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-Light.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Light.woff2') format('woff2'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Light.woff') format('woff'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Light.ttf') format('truetype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Light.svg#BarlowCondensed-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-Regular.eot');
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-Regular.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Regular.woff2') format('woff2'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Regular.woff') format('woff'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Regular.ttf') format('truetype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Regular.svg#BarlowCondensed-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-Medium.eot');
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-Medium.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Medium.woff2') format('woff2'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Medium.woff') format('woff'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Medium.ttf') format('truetype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Medium.svg#BarlowCondensed-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-SemiBold.eot');
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-SemiBold.woff2') format('woff2'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-SemiBold.woff') format('woff'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-SemiBold.ttf') format('truetype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-SemiBold.svg#BarlowCondensed-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-Bold.eot');
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-Bold.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Bold.woff2') format('woff2'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Bold.woff') format('woff'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Bold.ttf') format('truetype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Bold.svg#BarlowCondensed-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-ExtraBold.eot');
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-ExtraBold.woff2') format('woff2'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-ExtraBold.woff') format('woff'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-ExtraBold.ttf') format('truetype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-ExtraBold.svg#BarlowCondensed-ExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-Black.eot');
  src: url('assets/fonts/barlow-condensed/BarlowCondensed-Black.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Black.woff2') format('woff2'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Black.woff') format('woff'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Black.ttf') format('truetype'),
  url('assets/fonts/barlow-condensed/BarlowCondensed-Black.svg#BarlowCondensed-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* FONT LATO */
@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/lato/Lato-Light.eot');
  src: url('assets/fonts/lato/Lato-Light.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/lato/Lato-Light.woff2') format('woff2'),
  url('assets/fonts/lato/Lato-Light.woff') format('woff'),
  url('assets/fonts/lato/Lato-Light.ttf') format('truetype'),
  url('assets/fonts/lato/Lato-Light.svg#Lato-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/lato/Lato-Regular.eot');
  src: url('assets/fonts/lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/lato/Lato-Regular.woff2') format('woff2'),
  url('assets/fonts/lato/Lato-Regular.woff') format('woff'),
  url('assets/fonts/lato/Lato-Regular.ttf') format('truetype'),
  url('assets/fonts/lato/Lato-Regular.svg#Lato-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/lato/Lato-Bold.eot');
  src: url('assets/fonts/lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/lato/Lato-Bold.woff2') format('woff2'),
  url('assets/fonts/lato/Lato-Bold.woff') format('woff'),
  url('assets/fonts/lato/Lato-Bold.ttf') format('truetype'),
  url('assets/fonts/lato/Lato-Bold.svg#Lato-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/lato/Lato-Black.eot');
  src: url('assets/fonts/lato/Lato-Black.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/lato/Lato-Black.woff2') format('woff2'),
  url('assets/fonts/lato/Lato-Black.woff') format('woff'),
  url('assets/fonts/lato/Lato-Black.ttf') format('truetype'),
  url('assets/fonts/lato/Lato-Black.svg#Lato-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* FONT ICON */
@font-face {
  font-family: 'Icon Font';
  src:  url('assets/fonts/icon/icomoon.eot?4d5ecr');
  src:  url('assets/fonts/icon/icomoon.eot?4d5ecr#iefix') format('embedded-opentype'),
  url('assets/fonts/icon/icomoon.woff2?4d5ecr') format('woff2'),
  url('assets/fonts/icon/icomoon.ttf?4d5ecr') format('truetype'),
  url('assets/fonts/icon/icomoon.woff?4d5ecr') format('woff'),
  url('assets/fonts/icon/icomoon.svg?4d5ecr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'Icon Font' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow::before { //a
  content: "\61";
}
.icon-check::before { //b
  content: "\62";
}
.icon-plus::before { //c
  content: "\63";
}
.icon-minus::before { //d
  content: "\64";
}
.icon-search::before { //e
  content: "\65";
}
.icon-delete::before { //f
  content: "\66";
}
.icon-date::before { //g
  content: "\67";
}
.icon-document::before { //h
  content: "\68";
}
.icon-duplicate::before { //i
  content: "\69";
}
.icon-edit::before { //j
  content: "\6a";
}
.icon-link::before { //k
  content: "\6b";
}
.icon-save::before { //l
  content: "\6c";
}
.icon-template::before { //m
  content: "\6d";
}
.icon-dragdrop::before { //n
  content: "\6e";
}
.icon-libero-professionista:before { //o
  content: "\6f";
}
.icon-salute-amica:before { //p
  content: "\70";
}
.icon-exit::before { //q
  content: "\71";
}
.icon-print::before { //r
  content: "\72";
}
.icon-restore::before { //s
  content: "\73";
}
.icon-ok::before { //t
  content: "\74";
}
.icon-ko::before { //u
  content: "\75";
}
.icon-image::before { //v
  content: "\76";
}

