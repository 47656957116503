/* --- TYPOGRAPHY --- */
$font-default:					    'Lato', sans-serif;
$font-title:					      'Barlow Condensed', sans-serif;
$font-icon:			 			      'Icon Font';

$font-thin:             		100; //NO Lato
$font-extralight:        		200; //NO Lato
$font-light:             		300;
$font-regular:           		400;
$font-medium:          		  500; //NO Lato
$font-semibold:          		600; //NO Lato
$font-bold:              		700;
$font-extrabold:           	800; //NO Lato
$font-black:              	900;

/* --- COLORS --- */
$black:                     #000000;
$white:                     #ffffff;
$transparent:               #ffffff00;
$ko-color:					        #9B5252;
$ok-color:				          #4F8A10;
$deprecated-color:     #FFA500;

$brand-color-01:            #0082C6;
$brand-color-02:            #529B65;
$brand-color-03:            #E6E6E6;
$brand-color-04:            #005784;
$brand-color-05:            #989898;
$brand-color-06:            #797979;
$brand-color-07:            #707070;

$brand-color-08:            #529B65;
$brand-color-09:            #AF2424;
$brand-color-10:            #FF7C0B;
$brand-color-11:            #E7F3FA;

$primary:                   $brand-color-01;
$secondary:                 $brand-color-02;
$tertiary:                  $brand-color-03;


/* --- ANIMATIONS --- */
$bezier:                    cubic-bezier(0.090, 0.955, 0.450, 0.985);
$standard-transition:       all 0.5s $bezier;
