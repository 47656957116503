/* ADDICTION RADIO */
addiction-radio {
  width: 100%;
  height: auto;
  min-height: 47px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .form-field {
    width: 100%;
    display: flex;
    flex-flow: column;

    > label {
      @include fieldLabel;
    }

    mat-radio-group {
      width: 100%;
      display: flex;
      column-gap: 20px;

      mat-radio-button {
        --mdc-radio-state-layer-size: 18px !important;
        --mdc-radio-disabled-selected-icon-color: transparent !important;
        --mdc-radio-disabled-unselected-icon-color: transparent !important;
        --mdc-radio-unselected-hover-icon-color: transparent !important;
        --mdc-radio-unselected-icon-color: transparent !important;
        --mdc-radio-unselected-pressed-icon-color: transparent !important;
        --mdc-radio-selected-focus-icon-color: transparent !important;
        --mdc-radio-selected-hover-icon-color: transparent !important;
        --mdc-radio-selected-icon-color: transparent !important;
        --mdc-radio-selected-pressed-icon-color: transparent !important;
        --mat-radio-ripple-color: transparent !important;
        --mat-radio-checked-ripple-color: transparent !important;
        --mat-radio-disabled-label-color: inherit !important;

        &.no-label {
          margin-right: -10px;

          .mdc-form-field {

            .mdc-label {
              display: none;
            }

          }

        }

        &.mat-mdc-radio-checked {

          .mdc-form-field {

            .mdc-radio {

              .mdc-radio__background {

                .mdc-radio__inner-circle {
                  transform: scale(1);
                }

              }

            }

          }

        }

        .mdc-form-field {
          display: flex;

          .mdc-radio {
            width: 18px;
            height: 18px;
            padding: 0;

            .mat-mdc-radio-touch-target {
              width: 18px;
              height: 18px;
            }

            input {
              width: 18px;
              height: 18px;
            }

            .mdc-radio__background {
              width: 18px;
              height: 18px;
              @include flex-center;

              .mdc-radio__outer-circle {
                width: 18px;
                height: 18px;
                background: transparentize($white, 0.9);
                border: none;
                position: relative;
              }

              .mdc-radio__inner-circle {
                width: 8px;
                height: 8px;
                background: $white;
                border: none;
                position: absolute;
                top: unset;
                left: unset;
                transform: scale(0);
                transition: $standard-transition;
              }

            }

            .mat-ripple {
              display: none;
            }

          }

          .mdc-label {
            @include font-style(14px, $font-regular, $white, 0, 130%);
            margin-left: 10px;
            padding: 0;
          }

        }

      }

    }

  }

}
