/* Stile di base dentro modal-header.component.scss */
/* DATALEAN MODAL HEADER */
datalean-modal-header {
  height: auto !important;
  padding: 30px 30px 10px 30px;

  .modal-title {
    display: flex;
    flex-flow: column;
    margin-bottom: 0 !important;

    .left {
      width: 100%;
      @include flex-center;

      .title {
        @include font-style(30px, $font-light, $ko-color, 0, 100%);
        word-break: break-all;
      }

    }

    .right {
      display: none !important;
    }

  }

}
